body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
/** 全局搜索区域 start **/
.common-search-section {
  display: flex;
}
.common-search-section .date-search-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  color: #333;
  margin-bottom: 18px;
}
.common-search-section .search-item {
  margin: 0 10px 12px 0;
  height: 36px;
  min-height: 32px;
}
.common-search-section .search-item .search-label {
  height: 36px;
  line-height: 36px;
}
.common-search-section .search-item .ant-select-selection {
  min-height: 36px;
  height: auto;
  line-height: 36px;
}
.common-search-section .search-item .ant-select-selection .ant-select-selection__rendered {
  line-height: 32px;
}
.common-search-section .ant-input-affix-wrapper {
  margin-right: 16px;
}
.common-search-section .ant-input-affix-wrapper .ant-input-suffix {
  top: 18px;
}
.common-search-section .time-item {
  position: relative;
  display: inline-block;
  padding: 0 15px;
  font-size: 13px;
  cursor: pointer;
  color: #666666;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
}
.common-search-section .time-item:hover {
  color: #333333;
}
.common-search-section .time-selected {
  color: #369fff;
}
.common-search-section .time-selected:hover {
  color: #369fff;
}
.common-search-section .time-item + .time-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  transform: scaleY(0.5);
  border-left: 1px solid #bbb;
}
.common-search-section .ant-calendar-picker-input {
  height: 36px;
}
.common-search-section .ant-calendar-picker + .ant-calendar-picker {
  margin-left: 26px;
  position: relative;
}
.common-search-section .ant-calendar-picker + .ant-calendar-picker:before {
  content: '';
  height: 1px;
  width: 10px;
  background-color: #c0c0c0;
  position: absolute;
  top: 15px;
  left: -18px;
}
.common-search-section .ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field {
  height: 28px;
}
.common-search-section .ant-select-tree li > span.ant-select-tree-switcher,
.common-search-section .ant-select-tree li > span.ant-select-tree-iconEle {
  width: 0px;
  height: 0px;
}
.common-search-section .ant-select-tree li {
  margin: 0 0 8px 0;
  height: 28px;
  line-height: 22px;
}
.common-search-section .ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  height: 28px;
}
.common-search-section .ant-select-tree li .ant-select-tree-node-content-wrapper {
  width: 100%;
  line-height: 22px;
}
.common-search-section .ant-select-tree-dropdown .ant-select-not-found {
  padding: 3px 7px;
  height: 36px;
}
.common-search-section .ant-cascader-input {
  height: 36px;
}
.common-search-section .ant-cascader-picker + .ant-cascader-picker {
  margin-left: 26px;
  position: relative;
}
.common-search-section .ant-cascader-picker + .ant-cascader-picker:before {
  content: '';
  height: 1px;
  width: 10px;
  background-color: #c0c0c0;
  position: absolute;
  top: 15px;
  left: -18px;
}
/** 全局搜索区域 end **/
:global .ant-table-pagination {
  float: none!important;
  display: table;
  margin: 30px auto 0 !important;
}
.ant-layout-content {
  overflow-y: scroll;
}
.ant-pagination-total-text {
  float: right;
  margin-right: 0;
  margin-left: 10px;
  font-size: 14px;
}
.search-item {
  margin: 0 10px 12px 0;
  height: 36px;
  min-height: 32px;
}
.search-item .search-label {
  height: 36px;
  line-height: 36px;
}
.search-item .ant-select-selection {
  min-height: 36px;
  height: auto;
  line-height: 36px;
}
.search-item .ant-select-selection .ant-select-selection__rendered {
  line-height: 32px;
}
.single-search-container {
  padding-right: 50px;
}
.single-search-container .single-search {
  width: 100%;
}
.single-search-container .single-search .ant-select-auto-complete .ant-select-selection--single {
  margin-right: -46px;
}
.single-search-container .single-search .ant-select-auto-complete .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 62px;
}
.single-search-container .single-search .ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix {
  right: 0;
}
.single-search-container .single-search .ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.single-search-container .single-search-item-count {
  position: absolute;
  right: 16px;
}
.single-search-container .ant-input-affix-wrapper .ant-input-suffix {
  right: 0;
}
