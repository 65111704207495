:global .ant-table {
  font-size: 12px !important;
}
:global .ant-table .ant-table-thead > tr > th {
  background: #fbfbfb;
  color: #333;
  font-weight: bold;
}
:global .ant-table .ant-table-tbody > tr:nth-of-type(even) {
  background-color: #fbfbfb;
}
:global .ant-table .ant-table-tbody > tr > td {
  border-bottom: none;
}
:global .ant-table.ant-table-small .ant-table-body > table {
  padding: 0;
}
:global .ant-btn-primary {
  background-color: #369fff;
  border-color: #369fff;
}
:global .ant-btn-primary.disabled,
:global .ant-btn-primary[disabled],
:global .ant-btn-primary.disabled:hover,
:global .ant-btn-primary[disabled]:hover,
:global .ant-btn-primary.disabled:focus,
:global .ant-btn-primary[disabled]:focus,
:global .ant-btn-primary.disabled:active,
:global .ant-btn-primary[disabled]:active,
:global .ant-btn-primary.disabled.active,
:global .ant-btn-primary[disabled].active {
  color: #ffffff;
  background-color: #cbcbcb;
  border-color: #cbcbcb;
}
:global .common-large-btn {
  width: 270px;
  height: 48px;
  background-color: #369fff;
  color: #ffffff;
  border-color: #369fff;
  border-radius: 4px;
}
:global .common-btn {
  width: 100px;
  height: 36px;
  background-color: #369fff;
  color: #ffffff;
  border-color: #369fff;
  border-radius: 4px;
}
:global .common-btn.ant-btn:not(.ant-btn-primary) {
  background-color: #eeeeee;
  border-color: #eeeeee;
  color: #333333;
}
:global .common-btn.cancel-btn {
  margin-left: 40px;
}
:global .common-small-btn {
  padding: 0;
  width: 60px;
  height: 32px;
  background-color: #369fff;
  color: #ffffff;
  border-color: #369fff;
  border-radius: 4px;
}
:global .common-small-btn.ant-btn:not(.ant-btn-primary) {
  background-color: #eeeeee;
  border-color: #eeeeee;
  color: #333333;
}
:global .ant-btn:not(.ant-btn-primary):hover,
:global .ant-btn:not(.ant-btn-primary):active {
  background-color: #e1e1e1;
  border-color: #e1e1e1;
  color: #333;
}
:global .ant-btn-primary:hover,
:global .ant-btn-primary:active {
  background-color: #1c7bef;
  border-color: #1c7bef;
}
:global .common-operate-btns {
  margin-top: 80px;
  text-align: center;
}
:global .common-operate-btns .common-small-btn {
  margin-right: 20px;
}
:global :root .ant-table-pagination {
  float: none!important;
  display: table;
  margin: 30px auto 0 !important;
}
:root .ant-pagination-item-active {
  background-color: #369fff;
  border-color: #369fff;
}
:root .ant-pagination-item-active:hover,
:root .ant-input-search-icon:hover {
  border-color: #369fff;
}
:root .ant-pagination-item-active:hover a,
:root .ant-pagination-item-active a {
  color: #fff;
}
:root .ant-pagination {
  font-size: 12px;
}
:root .ant-pagination:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}
:root .ant-pagination-total-text {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin-right: 10px;
}
:root .ant-pagination-item {
  cursor: pointer;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  list-style: none;
  display: inline-block;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  margin-right: 8px;
  font-family: Arial;
}
:root .ant-pagination-item a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.65);
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  margin: 0 6px;
}
:root .ant-pagination-item:hover {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-color: #108ee9;
}
:root .ant-pagination-item:hover a {
  color: #108ee9;
}
:root .ant-pagination-item-active {
  background-color: #108ee9;
  border-color: #108ee9;
}
:root .ant-pagination-item-active a,
:root .ant-pagination-item-active:hover a {
  color: #fff;
}
:root .ant-pagination-jump-prev:after,
:root .ant-pagination-jump-next:after {
  content: "•••";
  display: block;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
}
:root .ant-pagination-jump-prev:hover:after,
:root .ant-pagination-jump-next:hover:after {
  color: #108ee9;
  display: inline-block;
  font-size: 12px;
  font-size: 8px \9;
  -webkit-transform: scale(0.66666667) rotate(0deg);
  -ms-transform: scale(0.66666667) rotate(0deg);
  transform: scale(0.66666667) rotate(0deg);
  /* IE6-IE8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=1, M12=0, M21=0, M22=1)";
  zoom: 1;
  letter-spacing: -1px;
  font-family: "anticon";
}
:root :root .ant-pagination-jump-prev:hover:after,
:root :root .ant-pagination-jump-next:hover:after {
  -webkit-filter: none;
  filter: none;
}
:root :root .ant-pagination-jump-prev:hover:after,
:root :root .ant-pagination-jump-next:hover:after {
  font-size: 12px;
}
:root .ant-pagination-jump-prev:hover:after {
  content: "\e620\e620";
}
:root .ant-pagination-jump-next:hover:after {
  content: "\e61f\e61f";
}
:root .ant-pagination-prev,
:root .ant-pagination-jump-prev,
:root .ant-pagination-jump-next {
  margin-right: 8px;
}
:root .ant-pagination-prev,
:root .ant-pagination-next,
:root .ant-pagination-jump-prev,
:root .ant-pagination-jump-next {
  font-family: Arial;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  list-style: none;
  min-width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: inline-block;
}
:root .ant-pagination-prev,
:root .ant-pagination-next {
  border: 1px solid #d9d9d9;
  background-color: #fff;
}
:root .ant-pagination-prev a,
:root .ant-pagination-next a {
  color: rgba(0, 0, 0, 0.65);
}
:root .ant-pagination-prev a:after,
:root .ant-pagination-next a:after {
  display: inline-block;
  font-size: 12px;
  font-size: 8px \9;
  -webkit-transform: scale(0.66666667) rotate(0deg);
  -ms-transform: scale(0.66666667) rotate(0deg);
  transform: scale(0.66666667) rotate(0deg);
  /* IE6-IE8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=1, M12=0, M21=0, M22=1)";
  zoom: 1;
  display: block;
  height: 26px;
  line-height: 27px;
  font-family: "anticon";
  text-align: center;
}
:root :root .ant-pagination-prev a:after,
:root :root .ant-pagination-next a:after {
  -webkit-filter: none;
  filter: none;
}
:root :root .ant-pagination-prev a:after,
:root :root .ant-pagination-next a:after {
  font-size: 12px;
}
:root .ant-pagination-prev:hover,
:root .ant-pagination-next:hover {
  border-color: #108ee9;
}
:root .ant-pagination-prev:hover a,
:root .ant-pagination-next:hover a {
  color: #108ee9;
}
:root .ant-pagination-prev a:after {
  content: "\e620";
  display: block;
}
:root .ant-pagination-next a:after {
  content: "\e61f";
  display: block;
}
:root .ant-pagination-disabled {
  cursor: not-allowed;
}
:root .ant-pagination-disabled:hover {
  border-color: #d9d9d9;
}
:root .ant-pagination-disabled:hover a {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
:root .ant-pagination-disabled a {
  color: rgba(0, 0, 0, 0.25);
}
:root .ant-pagination-slash {
  margin: 0 10px 0 5px;
}
:root .ant-pagination-options {
  display: inline-block;
  margin-left: 15px;
}
:root .ant-pagination-options-size-changer {
  display: inline-block;
  margin-right: 10px;
}
:root .ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 28px;
  line-height: 28px;
}
:root .ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  width: 100%;
  height: 28px;
  cursor: text;
  font-size: 12px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin: 0 8px;
  width: 50px;
}
:root .ant-pagination-options-quick-jumper input::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}
:root .ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #ccc;
}
:root .ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: #ccc;
}
:root .ant-pagination-options-quick-jumper input:hover {
  border-color: #49a9ee;
}
:root .ant-pagination-options-quick-jumper input:focus {
  border-color: #49a9ee;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(16, 142, 233, 0.2);
  box-shadow: 0 0 0 2px rgba(16, 142, 233, 0.2);
}
:root .ant-pagination-options-quick-jumper input[disabled] {
  background-color: #f7f7f7;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
:root .ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #e2e2e2;
}
:root textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}
:root .ant-pagination-options-quick-jumper input-lg {
  padding: 6px 7px;
  height: 32px;
}
:root .ant-pagination-options-quick-jumper input-sm {
  padding: 1px 7px;
  height: 22px;
}
:root .ant-pagination-simple .ant-pagination-prev,
:root .ant-pagination-simple .ant-pagination-next {
  border: 0;
  height: 24px;
  line-height: 24px;
  margin: 0;
  font-size: 18px;
}
:root .ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  margin-right: 8px;
}
:root .ant-pagination-simple .ant-pagination-simple-pager input {
  margin: 0 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  outline: none;
  padding: 5px 8px;
  width: 30px;
  height: 24px;
  text-align: center;
  -webkit-transition: border-color 0.3s ease;
  -o-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
}
:root .ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #108ee9;
}
:root .ant-pagination.mini .ant-pagination-total-text {
  height: 20px;
  line-height: 20px;
}
:root .ant-pagination.mini .ant-pagination-item {
  border: 0;
  margin: 0;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
}
:root .ant-pagination.mini .ant-pagination-prev,
:root .ant-pagination.mini .ant-pagination-next {
  margin: 0;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  border: 0;
}
:root .ant-pagination.mini .ant-pagination-prev a:after,
:root .ant-pagination.mini .ant-pagination-next a:after {
  height: 20px;
  line-height: 20px;
}
:root .ant-pagination.mini .ant-pagination-jump-prev,
:root .ant-pagination.mini .ant-pagination-jump-next {
  height: 20px;
  line-height: 20px;
}
:root .ant-pagination.mini .ant-pagination-options {
  margin-left: 8px;
}
:root .ant-pagination.mini .ant-pagination-options-quick-jumper {
  height: 20px;
  line-height: 20px;
}
:root .ant-pagination.mini .ant-pagination-options-quick-jumper input {
  padding: 1px 7px;
  height: 22px;
  width: 44px;
}
@media only screen and (max-width: 1024px) {
  :root .ant-pagination-item-after-jump-prev,
  :root .ant-pagination-item-before-jump-next {
    display: none;
  }
}
:global .ant-form.common-operate-form {
  padding: 30px 60px 80px 60px;
  margin-top: 10px;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
:global .ant-form.common-operate-form .ant-form-item {
  margin-top: 30px;
  margin-bottom: 0px;
}
:global .ant-form.common-operate-form .has-success.has-feedback:after,
:global .ant-form.common-operate-form .has-error.has-feedback:after {
  right: -40px;
}
:global .ant-form.common-operate-form .ant-col-12 .ant-form-item-control-wrapper {
  max-width: 260px;
  margin-left: 20px;
  height: 32px;
}
:global .ant-form.common-operate-form .ant-form-item-label {
  width: 80px;
  text-align: left;
}
:global .ant-form.common-operate-form .ant-form-item-label label {
  font-size: 13px;
  color: #333;
}
:global .ant-form.common-operate-form .ant-form-item-label label:after {
  content: '';
  margin: 0;
}
